import { inject, Injectable, signal } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { ApiProviderService } from '@/shared/lib/services/api-provider.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  sdk = inject(ApiProviderService).sdk;

  notificationsCountsByCategory = signal<{ [key: string]: number }>({
    shipments: 0,
    general: 0,
  });

  async loadAllCounts() {
    const shipmentsCount = await this.getUnreadNotificationsCount('shipments');
    const generalCount = await this.getUnreadNotificationsCount('general');

    this.notificationsCountsByCategory.set({
      shipments: shipmentsCount,
      general: generalCount,
    });
  }

  async getUnreadNotificationsCount(category: string): Promise<number> {
    const filterParams = new Map<string, string | string[] | Date[] | Date>();

    let entityType: string[] = [];
    switch (category) {
      case 'shipments':
        entityType = ['ShipmentPriorityEntity'];
        break;
      case 'general':
        entityType = ['AddressEntity'];
        break;
    }

    filterParams.set('eqRelatedEntityType', entityType);
    filterParams.set('eqIsRead', '0');

    const notifications = await this.getNotifications(0, 1000, filterParams);
    return notifications.total;
  }

  deleteNotification = (id: string) => {
    return firstValueFrom(this.sdk.notificationsApi.notificationDelete({ notificationDelete: { id: id } }));
  };

  restoreNotification = (id: string) => {
    return firstValueFrom(this.sdk.notificationsApi.notificationRestore({ notificationRestore: { id: id } }));
  };

  markNotificationAsRead = (id: string) => {
    return firstValueFrom(this.sdk.notificationsApi.notificationMarkAsRead({ notificationMarkAsRead: { id: id } }));
  };

  markNotificationAsUnread = (id: string) => {
    return firstValueFrom(this.sdk.notificationsApi.notificationMarkAsUnread({ notificationMarkAsUnread: { id: id } }));
  };

  markNotificationAsFavorite = (id: string) => {
    return firstValueFrom(
      this.sdk.notificationsApi.notificationMarkAsFavorite({ notificationMarkAsFavorite: { id: id } })
    );
  };

  markNotificationAsUnfavorite = (id: string) => {
    return firstValueFrom(
      this.sdk.notificationsApi.notificationMarkAsUnfavorite({ notificationMarkAsUnfavorite: { id: id } })
    );
  };

  archiveNotification = (id: string) => {
    return firstValueFrom(this.sdk.notificationsApi.notificationArchive({ notificationArchive: { id: id } }));
  };

  getNotifications = (
    pageNumber: number,
    pageSize: number,
    filterParams?: Map<string, string | string[] | Date[] | Date>
  ) => {
    return firstValueFrom(
      this.sdk.notificationsApi.listUserNotification({
        pageNumber: pageNumber,
        pageSize: pageSize,
        sortDirection: 'descending',
        sortField: 'CreatedAt',
        ...this.convertFilterParams(filterParams),
      })
    );
  };

  getDeletedNotifications = (
    pageNumber: number,
    pageSize: number,
    filterParams?: Map<string, string | string[] | Date[] | Date>
  ) => {
    return firstValueFrom(
      this.sdk.notificationsApi.listUserNotificationDeleted({
        pageNumber: pageNumber,
        pageSize: pageSize,
        sortDirection: 'descending',
        sortField: 'CreatedAt',
        ...this.convertFilterParams(filterParams),
      })
    );
  };

  convertFilterParams = (filterParams?: Map<string, string | string[] | Date[] | Date>) => {
    if (!filterParams) {
      return {};
    }

    return Object.fromEntries(filterParams.entries());
  };

  formatNotificationMessage = (message: string): string => {
    const guidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;

    message = message
      .replace(guidRegex, () => '')
      .replace('by admin:', 'by admin')
      .replace('created:', 'created')
      .replace('. ', '');

    message = message.replace(/Priority updated on shipment (\w+) to level 1/gi, 'Priority added on shipment $1');
    message = message.replace(/Priority set on shipment (\w+) to level 1/gi, 'Priority added on shipment $1');
    message = message.replace(/Priority set on shipment (\w+) to level 0/gi, 'Priority removed on shipment $1');

    if (message.trim().endsWith('to level')) {
      message = message.replace(/Priority updated on shipment (\w+) to level/gi, 'Priority removed on shipment $1');
    }

    return message;
  };
}
